import api from "./api.ts";

class RequesterService {
    async GetRequesters(accessToken: string) {     
        var path= "api/requesters"
        return api.Get(path, accessToken);
    }
}

const requesterService = new RequesterService();
export default requesterService;