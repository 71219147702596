class Api { 

    async Get(path, accessToken) {
        const response = await fetch(`${path}`,
            {
                headers: {
                'Authorization': `Bearer ${accessToken}`
                }
            }
        );
        const data = await response.json();

        if (response.status !== 200) {
            if (data.detailedMessage.includes("Not Found")) {
                throw Error("Asset not found")
            }
            throw Error(data.detailedMessage);     
        }
        return data;
    }


    async Put(path, accessToken) {
        const response = await fetch(`${path}`,
            {
                method: "PUT", 
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );
        if (response.status !== 200) {
            const data = await response.json();
            throw Error(data.detailedMessage);
        }
    }


    async Post(path, accessToken) {
        const response = await fetch(`${path}`,
            {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );
        if (response.status !== 200) {
            const data = await response.json();
            throw Error(data.detailedMessage);
        }
    }


    async Delete(path, accessToken) {
        const response = await fetch(`${path}`,
            {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }
        );
        if (response.status !== 200) {
            const data = await response.json();
            throw Error(data.detailedMessage);
        }
    }


}


const api = new Api();
export default api

