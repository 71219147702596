import { SignInButton } from "./SignInButton";

const Login = () => {
    return (
        <div id="main" className="text-center">
            <h1>Please login</h1>
            <SignInButton />
        </div>
    );
}

export default Login