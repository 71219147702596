import api from "./api.ts";


class AssetService {
    async GetAsset(assetId: string, accessToken: string) {
        var path = `api/assets/${assetId}?include=type_fields`;
        return api.Get(path, accessToken);      
    }

    async AssignAsset(assetId: string, userId: string, accessToken: string) {
        var path = `api/assets/${assetId}/${userId}`
        return api.Put(path, accessToken);
    }

    async ClearAsset(assetId: string, accessToken: string) {
        var path = `api/assets/${assetId}`;
        return api.Delete(path, accessToken);
    }
}


const assetService = new AssetService();
export default assetService






