import React, { Component, useState } from 'react';
import './custom.css';
import { PageLayout } from "./components/PageLayout";
import { useIsAuthenticated } from "@azure/msal-react";
import Login from './components/Login';
import Assets from './components/Assets';
import { Routes, Route } from 'react-router-dom';
import AppRoutes from './AppRoutes'




function App() {
    const isAuthenticated = useIsAuthenticated();
    if (isAuthenticated) {
        return (
            <PageLayout>
                {/*<Routes>*/}
                {/*    {AppRoutes.map((route, index) => {*/}
                {/*        const { element, ...rest } = route;*/}
                {/*        return <Route key={index} {...rest} element={element} />;*/}
                {/*    })}*/}
                {/*</Routes>*/}
                <Assets />
            </PageLayout>);
    }

    return (
        <PageLayout>
            <Login />
        </PageLayout>);
}


export default App;
