export const msalConfig = {
    auth: {
        clientId: "8c343862-41ed-48b2-ba2c-9fbd66405b69",
        authority: "https://login.microsoftonline.com/9f08805a-b141-4a2d-923f-80d0576d602a", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "https://freshservice.oslofjord.com",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["api://8c343862-41ed-48b2-ba2c-9fbd66405b69/freshservice.write"]
};
