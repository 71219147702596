import React, { Component, useLayoutEffect, useEffect, useState, useContext } from 'react';
import assetService from '../services/assetService.ts';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import requesterService from '../services/requesterService.ts';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { useMsal } from "@azure/msal-react";





const Assets = () => {
    const { instance, accounts, inProgress } = useMsal();
    var [accessToken, setAccessToken] = useState(null)
    var [assetId, setAssetId] = useState("")
    var [requesterId, setRequesterId] = useState("")
    var [requesters, setRequesters] = useState([])
    var [val, setVal] = useState({})
    const [openSuccess, setOpenSuccess] = useState(false);
    var [successText, setSuccessText] = useState("")
    const [openError, setOpenError] = useState(false);
    var [errorText, setErrorText] = useState("")




    //Fetch the access token and start api call to get the requester
    useEffect(() => {
        RequestAccessToken()
    }, [])

    useEffect(() => {
        if (accessToken != null) {
            GetRequesters()
        }
    }, [accessToken]);
    
    

    return (
        <div id="main" className="text-center m-auto">
            <h1>Assets</h1>
            <form>
                <label htmlFor="assetTag" className="d-block">Asset id</label>
                <input type="text" onChange={(event) => { setAssetId(event.target.value) }} className="form-control w-50 d-inline mx-3" id="assetId" placeholder="Asset id"></input>
                <button className="btn btn-primary" onClick={(e) => GetAsset(e)}>Search for asset</button>
                <label htmlFor="requesters" className="d-block">Assigned to</label>

                <Autocomplete
                    sx={{
                        width: 0.7,
                        mx: 'auto',
                        background: 'white'
                    }}
                    onChange={(event, value) => {
                        setRequesterId(value.id.toString())
                        setVal(value)
                    }}
                    value={val || null}
                    id="requesters"
                    groupBy={(option) => option.email}
                    getOptionLabel={(option) => {
                        if (option.firstName && option.lastName) { return option.firstName + " " + option.lastName }
                        else if (option.firstName) {
                            { return option.firstName }
                        }
                        else { return "" }
                    }}
                    options={requesters}
                    filterOptions={(options, state) => {
                        if (state.inputValue.length > 2) {
                            return options.filter((item) =>
                                String(item.firstName + " " + item.lastName)
                                    .toLowerCase()
                                    .includes(state.inputValue.toLowerCase())
                            );
                        }
                        return options.slice(0, 30);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Requesters"
                            InputLabelProps={{
                                    style: { color: 'rgb(109, 116, 146)'},
                                }}

                        />
                    )}
                />

                <div className="my-3">
                    <button className="btn btn-primary mx-3 btn-clear" onClick={(e) => ClearAsset(e)}>Clear asset</button>
                    <button className="btn btn-primary mx-3 btn-assign" onClick={(e) => AssignAsset(e)}>Assign asset</button>
                </div>
            </form>


            <Collapse in={openSuccess}>
                <Alert
                    severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenSuccess(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        mb: 2,
                        width: 0.5,
                        mx: "auto"
                    }}
                >
                    {successText}
                </Alert>
            </Collapse>
            <Collapse in={openError}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenError(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        mb: 2,
                        width: 0.5,
                        mx: "auto"
                    }}
                >
                    {errorText}
                </Alert>
            </Collapse>
        </div>
    );


    ///
    ///Get the user access token
    ///
     async function RequestAccessToken() {
        const request = {
            scopes: ["api://8c343862-41ed-48b2-ba2c-9fbd66405b69/freshservice.write"],
            account: accounts[0]
        };
   
        // Silently acquires an access token
        await instance.acquireTokenSilent(request).then((response) => {
            setAccessToken(response.accessToken);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                setAccessToken(response.accessToken);
            });
        });

        return accessToken
    }

    async function GetAsset(e) {
        e.preventDefault();
        setOpenSuccess(false)
        setOpenError(false)

        if (assetId == "") { return }

        try {
            assetId = document.getElementById("assetId").value;
            var resp = await assetService.GetAsset(assetId, accessToken)

            var owner = requesters.find(r => r.id == resp.userId)
            setVal(owner)
        } catch (e) {
            setErrorText(e.message)
            setOpenError(true)
        }
    }


    async function AssignAsset(e) {
        e.preventDefault();
        setOpenSuccess(false)
        setOpenError(false)

        if (requesterId == null || requesterId.length == 0) {
            return
        }

        try {
            await assetService.AssignAsset(assetId, requesterId, accessToken)

            setSuccessText(`Linked asset with id: ${assetId} to ${val.firstName} ${val.lastName}`)
            setOpenSuccess(true)
        } catch (e) {
            setErrorText(e.message)
        }
    }


    async function ClearAsset(e) {
        e.preventDefault();
        setOpenSuccess(false)
        setOpenError(false)
        try {
            await assetService.ClearAsset(assetId, accessToken)
            setVal(null)

            setSuccessText(`Cleared asset with id: ${assetId}`)
            setOpenSuccess(true)
        } catch (e) {
            setErrorText(e.message)
            setOpenError(true)
        }

    }


    async function GetRequesters() {
        var resp = await requesterService.GetRequesters(accessToken);
        setRequesters(resp)
    }

    
}

export default Assets
